
/**
 * @name: thematicActivities
 * @author: Gzm
 * @date: 2023-06-02 10:30
 * @description：营销管理-专题活动
 * @update: 2023-06-02 10:30
 */
import {Component, Vue} from "vue-property-decorator";
import {ICrudOption} from "@/types/m-ui-crud";
import {ILevel} from "@/apis/customer/level/types";
import config from "@/config";
import {
  userWalletSetupCreateApi,
} from "@/apis/marketingManage/rules";
import {IWalletRules} from "@/apis/marketingManage/rules/types";
import {Message} from "element-ui";
import router from "@/router";
import {
  queryThematicActivitiesListApi,
  thematicActivitiesBatchDeleteApi,
  upAndDownShelfApi
} from "@/apis/marketingManage/thematicActivities";

@Component({})
export default class LevelPage extends Vue {
  selectRow: any = []
  config = config
  // 表格加载状态
  tableLoading: boolean = false;
  // 表格总数
  tableTotal: number = 0;
  // 表格数据
  tableData: ILevel[] = []
  // 表单
  modelForm: Partial<ILevel> = {}
  // 查询参数
  queryParam = {}
  queryForm: any = {
    page: 1,
    limit: 10
  }

  // crudOption
  option: ICrudOption = {
    menu:false,
    searchBox: false,
    column: [
      {
        label: "",
        type: "selection",
        width: 50,
        align: "center",
        viewHide: true
      },
      {
        "label": "专题活动名称",
        "prop": "activitiesName",
        "align": "center",
      },
      {
        "label": "赛事图片",
        "prop": "coverPic",
        "align": "center",
        imgPrefix: config.downloadUrl,
        type: 'image',
      },
      {
        "label": "赛事名称",
        "prop": "contestName",
        "align": "center",
      },
      // {
      //   "label": "预定金额",
      //   "prop": "reservePrice",
      //   "align": "center",
      // },
      // {
      //   "label": "报名人数",
      //   "prop": "enrollNumber",
      //   "align": "center",
      // },
      // {
      //   "label": "报名名额",
      //   "prop": "enrollQty",
      //   "align": "center",
      // },
      {
        "label": "排序",
        "prop": "sort",
        "align": "center",
      },
      {
        label: "状态",
        prop: "status",
        align: 'center',
        slot: true,
        value: 2,
        type: "switch",//状态:1=有效 2=无效
        dicData: [{label: '下架', value: 2}, {label: '上架', value: 1}],
      },
      {
        label: "操作",
        prop: "operate",
        align: 'center',
        slot: true,
        addHide:true
      },

    ]
  }

  rowSave (form: IWalletRules, done: Function, loading: Function) {
    userWalletSetupCreateApi(form).then(e => {
      if (e) {
        done()
        this.$message.success("新增成功!")
        this.getList()
      }
    }).finally(() => {
      loading()
    })
  }
  /**
   * 删除
   * @param id
   */
  handleRemove(id: string) {
    this.$confirm('是否确认移除该专题活动?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      thematicActivitiesBatchDeleteApi(id).then(e => {
        if (e) {
          Message.success("移除成功!")
          this.getList()
        }
      })
    }).catch(() => {
    });
  }

  get selectIds() {
    return this.selectRow.map((item: any) => item.id)
  }

  handleBatch() {
    if (this.selectIds.length == 0) {
      return this.$message.error("请选择数据")
    }
    this.handleSettle(this.selectIds)
  }

  handleSettle(ids: string[]) {
    if (!ids || ids.length == 0) {
      return
    }
    this.$confirm('是否批量移除?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      let id = ids.join(',')
      thematicActivitiesBatchDeleteApi(id).then(e => {
        this.$message.success("操作成功!")
        this.getList()
      })
    }).catch(() => {

    });
  }

  getList () {
    this.tableLoading = true
    queryThematicActivitiesListApi().then(e => {
      this.tableLoading = false
      //@ts-ignore
      this.tableData = e
    })
  }

  async openAdd() {
    if (this.tableData.length == 5){
      return Message.error('最多五条专题活动，请先移除后新增')
    }
    router.push({path:'/marketingManage/thematicAdd'})
  }

  /**
   * 修改状态提交
   */
  handleStatus(row: any, val: number) {
    upAndDownShelfApi({id: row.id, status: val}).then(e => {
      if (e) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.getList()
      }
    })
  }

  /**
   * 修改前,添加前
   * @param  item: any
   */
  handleEditOrAdd(item?: any) {
    let url='/marketingManage/thematicAdd'
    if(item)
      url=url+`?id=${item.id}`
    router.push({path:url})
  }

  created () {
    this.getList()
  }
}
